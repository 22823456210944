<template>
  <div class="shopMain">
    <div class="c h sb sep-t">
      <div class="no-flex padding-10">分析区域</div>
      <el-radio-group v-model="query.addressType" size="mini">
        <el-radio label="area">自定义区域</el-radio>
        <el-radio label="standard">标准行政区</el-radio>
      </el-radio-group>
    </div>
    <div class="c h sep-t padding-05" @click="drawerShop">
      <div class="no-flex padding-05">{{ query.addressType === "area" ? "自定义区域" : "标准行政区" }}</div>
      <div class="flex ta-r padding-05">{{ query.addressType === "area" ? label.area : label.region }}</div>
      <i class="el-icon-arrow-right padding-05 fc-g"></i>
    </div>
    <template v-if="showOpt.showRangGoods">
      <div class="c h padding-05" @click="drawer.brand = true">
        <div class="no-flex padding-05">商品品牌</div>
        <div class="flex ta-r padding-05">{{ label.brand }}</div>
        <i class="el-icon-arrow-right padding-05 fc-g"></i>
      </div>
      <div class="c h sep-t padding-05" @click="drawer.series = true" v-if="query.brandId">
        <div class="no-flex padding-05">商品系列</div>
        <div class="flex ta-r padding-05">{{ label.series }}</div>
        <i class="el-icon-arrow-right padding-05 fc-g"></i>
      </div>
      <div class="c h sep-t padding-05" @click="drawer.category = true" v-if="showOpt.showGoodsType">
        <div class="no-flex padding-05">商品分类</div>
        <div class="flex ta-r padding-05">{{ label.category }}</div>
        <i class="el-icon-arrow-right padding-05 fc-g"></i>
      </div>
    </template>
    <div class="c h sep-t padding-05" @click="drawer.goods = true" v-if="showOpt.showCommodity">
      <div class="no-flex padding-05">指定商品</div>
      <div class="flex ta-r padding-05">{{ label.goods }}</div>
      <i class="el-icon-arrow-right padding-05 fc-g"></i>
    </div>
    <div class="c h sep-t sb" v-if="showOpt.showLevel">
      <div class="no-flex padding-10">汇总级别</div>
      <el-radio-group v-model="query.goodsGroup" size="mini">
        <el-radio label="brandId">品牌结构</el-radio>
        <el-radio label="seriesId">系列结构</el-radio>
        <el-radio label="goodsCategoryId">分类结构</el-radio>
        <el-radio label="skuId">商品结构</el-radio>
      </el-radio-group>
    </div>
    <div class="c h sep-t sb" v-if="showOpt.showWarehouseGroup">
      <div class="no-flex padding-10">排名对象</div>
      <el-radio-group v-model="query.warehouseGroup" size="mini">
        <el-radio label="province">省</el-radio>
        <el-radio label="city">市</el-radio>
        <el-radio label="district">区</el-radio>
        <el-radio label="distributor">经销商</el-radio>
        <el-radio label="warehouse">库房</el-radio>
      </el-radio-group>
    </div>
    <!-- 自定义区域 -->
    <custom-list :visible.sync="drawer.customShop" v-model="query.treeNodeId" :type.sync="query.treeNodeType" @change="handleShopChange" @init="handleInited" v-if="query.addressType == 'area'" />
    <!-- 标准行政区域-->
    <province-list :visible.sync="drawer.standardShop" v-model="query.treeNodeId" :type.sync="query.StandardAreaGroup" @change="handleShopChange" @init="handleInited" v-if="query.addressType == 'standard'" />
    <!-- 选指定商品 -->
    <sku-selector request-url="api/goods/sku" :visible.sync="drawer.goods" @change="handleGoodsSelect" />
    <!-- 商品范围 -->
    <div class="c h padding-10">
      <brand-selector v-model="query.brandId" :label.sync="label.brand" :visible.sync="drawer.brand" @change="handleBrandSelect" />
      <series-selector v-model="query.seriesId" :label.sync="label.series" :brand-id="query.brandId" :visible.sync="drawer.series" />
      <category-selector key="cate1" v-model="query.categoryId" :label.sync="label.category" :visible.sync="drawer.category" />
    </div>
  </div>
</template>

<script>
import customList from "../area/customList";
import provinceList from "../area/provinceList";
import skuSelector from "../selector/skuSelect";
import brandSelector from "../selector/brandSelect";
import seriesSelector from "../selector/seriesSelect";
import categorySelector from "../selector/categorySelect";
export default {
  props: {
    showOpt: Object,
    query: Object,
  },
  components: {
    customList,
    provinceList,
    skuSelector,
    brandSelector,
    seriesSelector,
    categorySelector,
  },
  data() {
    let now = new Date();
    return {
      loading: false,
      label: {
        brand: "全部",
        series: "全部",
        category: "全部",
        categoryB: "全部",
        goods: "全部",
        area: "所有",
        region: "全国",
      },
      goods: {
        brandName: null,
        seriesName: null,
        categoryName: null,
      },
      drawer: {
        customShop: false,
        standardShop: false,
        goods: false,
        brand: false,
        series: false,
        category: false,
      },
      store: {
        shopTitle: "全部",
      },
    };
  },
  methods: {
    handleInited() {
      this.$emit("init");
    },
    handleShopChange(data) {
      if (this.query.addressType === "area") {
        this.label.area = data ? data.name : "所有";
      } else {
        this.label.region = data ? data.name : "全国";
      }
    },
    handleGoodsSelect(res) {
      this.query.goods = res;
      if (res && res.length) {
        this.label.goods = res.map((o) => o.name).join("、");
      } else {
        this.label.goods = "全部";
      }
    },

    handleBrandSelect(res) {
      if (this.query.hasOwnProperty("seriesId")) {
        this.query.seriesId = null;
        this.label.series = "全部";
      }
    },
    drawerShop() {
      if (this.query.addressType == "area") {
        this.drawer.customShop = true;
      }
      if (this.query.addressType == "standard") {
        this.drawer.standardShop = true;
      }
    },
  },
};
</script>